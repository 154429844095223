import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';

import { HtmlService } from '../services/html.service';

@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.css']
})
export class WebComponent implements OnInit {

  site: string = '';
  htmlText: string = '';
  showHeader: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private _snackBar: MatSnackBar,
    private htmlService: HtmlService) {
    this.route.params.subscribe(params => {
      this.site = params['site'];
    });
  }

  ngOnInit(): void {
    // /church/80619/news/22
    let prefix = '';
    if (this.router.url.match(/\/church\/[0-9]+\/event\/[0-9]+/)) {
      let id = this.router.url;
      id = id.substring(id.indexOf('/church/') + 8);
      id = id.substring(0, id.indexOf('/'));
      prefix = 'v1/event/' + id + '/';
      this.showHeader = true;
    } else if (this.router.url.match(/\/church\/[0-9]+\/news\/[0-9]+/)) {
      let id = this.router.url;
      id = id.substring(id.indexOf('/church/') + 8);
      id = id.substring(0, id.indexOf('/'));
      prefix = 'v1/news/' + id + '/';
      this.showHeader = true;
    } else {
      prefix = 'assets/html/';
    }

    // site = datenschutz, impressum
    // event/526
    this.htmlService.loadHtml(
      prefix + this.site
    ).subscribe(
      data => {
        this.htmlText = data.toString();
      }, error => {
        this._snackBar.open('Es ist ein Fehler beim Laden aufgetreten', 'Ok', {
          duration: 3000,
        });
      });
  }

  goBack() {
    this.location.back();
  }
}
