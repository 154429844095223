import { Component, Input, OnInit } from '@angular/core';
import { ChurchInformation } from 'src/app/church';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  @Input() church: ChurchInformation = new ChurchInformation();

  constructor() {
  }

  ngOnInit(): void {
    // /church/123/info
  }

}
