<mat-toolbar *ngIf="!isIos">
    <button (click)="toggleDrawer()" mat-icon-button class="ka-icon">
        <mat-icon class="ka-select2">menu</mat-icon>
    </button>

    <span class="ka-ellipsis" *ngIf="text && text !== ' '">{{text}}</span>

</mat-toolbar>

<mat-toolbar *ngIf="isIos">
    <button *ngIf="!isStart" (click)="goHome()" class="ka-icon">
        <mat-icon class="ka-selectIos">arrow_back_ios</mat-icon>
        <span class="ka-nav-text">Start</span>
    </button>
    <button *ngIf="isStart" (click)="toggleDrawer()" class="ka-icon">
        <mat-icon class="ka-selectIos">menu</mat-icon>
    </button>

    <span class="example-spacer"></span>

    <div *ngIf="text && text !== ' '" [class]="isStart ? 'ka-pad-46' : 'ka-pad-80'">{{text}}</div>

    <span class="example-spacer"></span>
</mat-toolbar>