<div *ngIf="church.eventGroup">
    <div *ngFor="let group of church.eventGroup">
        <button class="ka-church-toggle ka-select" mat-icon-button (click)="toggle(group.date)">
            <mat-icon class="mat-icon-rtl-mirror">
                {{isExpanded(group.date) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            <span>{{group.date}}</span>
        </button>

        <div *ngIf="isExpanded(group.date)">
            <table style="margin-left: 50px;">
                <tr *ngFor="let m of group.events; let i = index" style="margin-bottom: 30px;">
                    <td style="width: 60px; vertical-align: top;">{{m.time}}</td>
                    <td>
                        <a class="ka-nolink" routerLink="event/{{m.id}}">
                            <div class="ka-select" style="margin-right: 30px; margin-bottom: 20px;">
                                {{m.title}}<br /><br />
                                <img *ngIf="m.imageUrl" [src]="m.imageUrl"
                                    style="float:left; padding-right: 8px; min-width: 150px; max-width: 30%;" />
                                <div style="color: gray;">
                                    <mat-icon style="padding-bottom: 5px; float: left; padding-right: 5px;">place
                                    </mat-icon>
                                    {{m.ort}}
                                </div>
                                <br />
                                {{m.description}}
                            </div>
                        </a>
                    </td>
                </tr>
            </table>
        </div>

    </div>

    <div class="ka-church-loading" *ngIf="
                !church.details.id
                || (church.details.id && church.details.id === '')">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
</div>

<div class="ka-church-nodata" *ngIf="church.eventsError
&& church.eventsError.length > 0">
    {{church.eventsError}}
</div>