import { Component, Output, isDevMode, EventEmitter, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Church } from '../church';
import { Location } from '@angular/common';

import { DebugService } from '../debug.service';
import { StarService } from '../services/star.service';

@Component({
  selector: 'app-menu2',
  templateUrl: './menu2.component.html',
  styleUrls: ['./menu2.component.css']
})
export class Menu2Component implements OnInit {

  @Input() church = new Church();
  @Output() clicked = new EventEmitter<any>();

  isStarred: boolean = false;
  isIos: boolean = false;
  search: boolean = false;

  constructor(
    private router: Router,
    private starService: StarService,
    private debugService: DebugService,
    private location: Location,
    private route: ActivatedRoute) {
    this.isIos = (
      /iPad|iPhone|iPod|MacIntel/.test(navigator.userAgent)
      || (/Mac/.test(navigator.userAgent) && navigator.maxTouchPoints > 2)
    );
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.search = (params.search);
        console.log(this.search);
      });
  }

  ngOnChanges(): void {
    this.isStarred = this.starService.isStarred(this.church.id);
  }

  toggleDrawer(): void {
    this.clicked.emit();
  }

  toggleStar(church: Church) {
    if (isDevMode()) console.log('toggle' + church.id);
    this.isStarred = this.starService.toggleStar(church);
    if (isDevMode()) console.log('isStarred=' + this.isStarred);
    if (church.id === '9999') {
      this.debugService.incCount();
    }
  }

  goHome() {
    this.router.navigate(['/'], {
      replaceUrl: true,
      skipLocationChange: true
    });
  }

  goBack() {
    this.location.back();
  }
}
