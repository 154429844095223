<div *ngIf="church.newsGroup">
    <div *ngFor="let group of church.newsGroup">
        <button class="ka-church-toggle ka-select" mat-icon-button (click)="toggle(group.date)">
            <mat-icon class="mat-icon-rtl-mirror">
                {{isExpanded(group.date) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            <span>{{group.date}}</span>
        </button>

        <div class="ka-select" *ngIf="isExpanded(group.date)">

            <a class="ka-nolink" routerLink="news/{{m.id}}" [class.ka-separator]="i > 0"
                *ngFor="let m of group.news; let i = index">
                <div class="ka-church-item">
                    {{m.title}}<br />
                    <img *ngIf="m.imageUrl" [src]="m.imageUrl" />
                    <br />
                    <br />
                    {{m.description}}
                </div>
            </a>
        </div>

    </div>

    <div class="ka-church-loading" *ngIf="
                    !church.details.id
                    || (church.details.id && church.details.id === '')">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
</div>

<div class="ka-church-nodata" *ngIf="church.newsError
&& church.newsError.length > 0">
    {{church.newsError}}
</div>