import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ChurchInformation } from '../church';
import { ChurchService } from '../services/church.service';

@Component({
  selector: 'app-church',
  templateUrl: './church.component.html',
  styleUrls: ['./church.component.css']
})
export class ChurchComponent implements OnInit {

  church: ChurchInformation = new ChurchInformation();
  churchId: number;
  environment: any;
  tabIndex: number;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private churchService: ChurchService) {
    this.churchId = 0;
    this.environment = environment;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.churchId = +params['churchId'];
    });

    this.churchService.getChurch(this.churchId).subscribe(
      church => {
        this.church = church;

        // go back to porevious tab
        if (this.churchService.tabIndex) {
          this.tabIndex = this.churchService.tabIndex;
        }
      },
      error => {
        this._snackBar.open('Es ist ein Fehler beim Laden aufgetreten.', 'Ok', {
          duration: 3000,
        });
      }
    );
  }

  goHome() {
    this.router.navigate(['/'], {
      replaceUrl: true,
      skipLocationChange: true
    });
  }

  goDatenschutz() {
    this.router.navigate(['/web/datenschutz']);
  }

  goImpressum() {
    this.router.navigate(['/web/impressum']);
  }

  go(url: string) {
    window.location.href = url;
  }

  selectedIndexChange(event: any) {
    this.churchService.tabIndex = event;
  }
}
