<a *ngIf="church" class="ka-church-widget ka-nolink" href="/church/{{church.id}}{{ search ? '?search=1' : ''}}">
    <div class="ka-select">
        <img aria-hidden src="/assets/icons/icon_church{{church.level}}{{dark?'b':''}}.png" height="25" />
        <div class="ka-church-block">
            <small style="color: gray;">{{church | churchtype}}</small>
            <br>
            <span style="font-size: 1.1em;">{{church.name}}</span><small *ngIf="isDebug"> ({{church.id}})</small>
            <br />
            <small style="color: gray;" *ngIf="!dark">
                <span *ngIf="church.strasse">{{church.strasse}}{{church.no ? ' ' + church.no : ''}}, </span>
                <span *ngIf="church.plz">{{church.plz}} </span></small>
            <small style="color: gray;">{{church.ort}}{{church.ortsteil ? '-' + church.ortsteil : ''}}</small>
        </div>
    </div>
</a>