<div *ngIf="church && church.details && church.details.id" style="padding: 5px 10px 0px 10px;">

    <div *ngIf="church.details.level !== 1">

        <img *ngIf="church.details.picurl" [src]="church.details.picurl" style="width: 100%;" />

        <h1>Kontakt</h1>
        <span *ngIf="church.details.anschrift">{{church.details.anschrift}}<br /></span>
        <span *ngIf="church.details.strasse">{{church.details.strasse}} {{church.details.no}}<br /></span>

        {{church.details.plz}} {{church.details.ort}}
        <span *ngIf="church.details.ortsteil">- {{church.details.ortsteil}}<br /></span>
        <br />

        <span *ngIf="church.details.tel">Tel.: {{church.details.tel}}<br /></span>

        <span *ngIf="church.details.email">E-Mail: {{church.details.email}}<br /></span>

        <span *ngIf="church.details.url">Web: <a *ngIf="church.details.url" class="ka-select ka-link"
                href="{{church.details.url}}" target="_blank">{{church.details.url}}</a></span>
    </div>

    <div *ngIf="church.details.level !== 1">
        <h1>Karte</h1>
        <img *ngIf="church.details.level !== 3" [src]="church.details.mapurl" style="width: 100%;" />
        <a class="ka-select" *ngIf="church.details.level === 3"
            href="https://www.google.com/maps/search/?api=1&query={{church.details.latitude/1000000.0}},{{church.details.longitude/1000000.0}}">
            <img [src]="church.details.mapurl" style="width: 100%;" />
        </a>
    </div>

    <div *ngIf="church.details.ausstattung">
        <h1>Besonderheiten</h1>
        <span>{{church.details.ausstattung}}<br /></span>
    </div>

    <div *ngIf="church.details.children">
        <h1>zugehörige Gottesdienstorte</h1>

        <div *ngFor="let c of church.details.children">
            <church-widget [church]="c"></church-widget>
        </div>
    </div>

    <div *ngIf="church.details.parent">
        <h1>{{church.details.patrozinium}} gehört zu</h1>

        <church-widget [church]="church.details.parent"></church-widget>
    </div>

    <br />
</div>