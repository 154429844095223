import { MassComponent } from "./church/mass/mass.component";

export class Church {
  id: string = '';
  patrozinium: string = '';
  volksname: string = '';
  name: string = '';
  strasse: string = '';
  no: string = '';
  ort: string = '';
  ortsteil: string = '';


  plz: number = 0;
  longitude: number = 0;
  latitude: number = 0;
  dist: number = 0;
  massAvailable: boolean = false;
  level: number = 0;
  parentId: string = '';
}

export class City {
  ort: string = '';
  ortsteil: string = '';
  plz: number = 0;
}

export class SearchResult {
  churches: Church[] = [];
  cities: City[] = [];
}

export class ChurchDetail extends Church {
  email: string = '';
  tel: string = '';
  kontakt: string = '';
  anschrift: string = '';
  url: string = '';
  picurl: string = '';
  mapurl: string = '';
  description: string = '';
  ausstattung: string = '';
  oeffnungszeiten: string = '';

  parent: Church = new Church();
  children: Church[] = [];
}

export class ChurchInformation {
  details: ChurchDetail = new ChurchDetail();
  massGroup: MassTuple[] = [];
  massesError: string = '';
  newsGroup: NewsTuple[] = [];
  newsError: string = '';
  eventGroup: EventTuple[] = [];
  eventsError: string = '';
  children: Church[] = [];
  parent: Church = new Church();
}

export class MassTuple {
  date: string = '';
  masses: Mass[] = [];
}

export class NewsTuple {
  date: string = '';
  news: News[] = [];
}

export class EventTuple {
  date: string = '';
  events: Event[] = [];
}

export class Mass {
  typ: string = '';
  id: string = '';
  date: string = '';
  time: string = '';
  timeTo: string = '';
  ort: string = '';
  description: string = '';
  imageUrl: string = '';
  icsUrl: string;
}

export class News {
  id: string = '';
  title: string = '';
  link: string = '';
  sharelink: string = '';
  imageUrl: string = '';
  description: string = '';
  date: string = '';
  location: string = '';
}

export class Event {
  id: string = '';
  date: string = '';
  time: string = '';
  timeTo: string = '';
  ort: string = '';
  description: string = '';
  imageUrl: string = '';
  title: string = '';
  link: string = '';
  sharelink: string = '';
  latitude: number = 0;
  longitude: number = 0;
}