import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Church, SearchResult, ChurchInformation } from '../church';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChurchService {

  tabIndex: number;

  headers = {
    headers: { 'x-app-version': environment.version + '-' + environment.buildtime }
  }

  constructor(private http: HttpClient) { }

  getChurchesByCity(city: string): Observable<Church[]> {
    return this.http.get<Church[]>(environment.serviceUrl + '/churches/city/' + city, this.headers);
  }

  getChurchesById(id: number): Observable<Church[]> {
    return this.http.get<Church[]>(environment.serviceUrl + '/churches/id/' + id + '?hierarchy=true', this.headers);
  }

  getChurchesByGeo(lng: number, lat: number): Observable<Church[]> {
    return this.http.get<Church[]>(environment.serviceUrl + '/churches/geo/' + lng + ',' + lat + ',20', this.headers);
  }

  getChurch(id: number): Observable<ChurchInformation> {
    return this.http.get<ChurchInformation>(environment.serviceUrl + '/church/' + id
      + '?filter=details,masses,news,events,hierarchy', this.headers);
  }

  query(q: string): Observable<SearchResult> {
    return this.http.get<SearchResult>(environment.serviceUrl + '/query/'
      + (q != null ? q : ''), this.headers);
  }



}
