<mat-drawer-container class="drawer-container" autosize>
    <mat-drawer #drawer mode="over">
        <ul class="drawer-menu">
            <li class="ka-select">
                <a class="drawer-menu-link" (click)="drawer.toggle()" (click)="goHome()">
                    <span class="material-icons">
                        home
                    </span>
                    <div class="drawer-menu-text">Start</div>
                </a>
            </li>
            <li class="ka-select">
                <a class="drawer-menu-link" (click)="drawer.toggle()" (click)="goDatenschutz()">
                    <span class="material-icons">
                        privacy_tip
                    </span>
                    <div class="drawer-menu-text">Datenschutz</div>
                </a>
            </li>
            <li class="ka-select">
                <a class="drawer-menu-link" (click)="drawer.toggle()" (click)="goImpressum()">
                    <span class="material-icons">
                        assignment
                    </span>
                    <div class="drawer-menu-text">Impressum</div>
                </a>
            </li>

            <div style="position: fixed; bottom: 10px; width: 250px;">
                <li class="ka-select">
                    <a class="drawer-menu-link" (click)="go('https://kirchenzeitung-aachen.de/')">
                        <img style="padding-left: 6px; " src="/assets/icons/kiz-logo.png" alt="Kirchenzeitung Aachen" />
                    </a>
                </li>
                <li>
                    <span style="padding-left: 16px; font-size: 0.6em;">{{environment.version}}</span>
                </li>
            </div>
        </ul>
    </mat-drawer>

    <div class="ka-tab-wrapper">
        <app-menu2 [church]="church.details" (clicked)="drawer.toggle()">
        </app-menu2>

        <mat-tab-group animationDuration="0ms" matTabGroupGesture [connectEdges]="false" [disablePagination]="true"
            [selectedIndex]="tabIndex" (selectedIndexChange)="selectedIndexChange($event)"
            style="height: calc(100% - 80px);">
            <mat-tab label="Gottesdienste">
                <app-mass [church]="church"></app-mass>
            </mat-tab>
            <mat-tab label="Info">
                <app-info [church]="church"></app-info>
            </mat-tab>
            <mat-tab *ngIf="church.details && church.details.level === 2" label="Termine">
                <app-events [church]="church"></app-events>
            </mat-tab>
            <mat-tab *ngIf="church.details && church.details.level === 2" label="Aktuell">
                <app-news [church]="church"></app-news>
            </mat-tab>
        </mat-tab-group>
        <br />

    </div>

</mat-drawer-container>