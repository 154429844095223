import { Component, OnInit, isDevMode } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ChurchService } from '../services/church.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  myControl = new UntypedFormControl();
  options: any[] = [];
  filteredOptions = new BehaviorSubject<any[]>([]);
  selected: any;

  constructor(
    private _snackBar: MatSnackBar,
    private churchService: ChurchService,
    private router: Router) {
  }

  ngOnInit() {

    this.myControl.valueChanges.subscribe(val => {
      this.selected = val;
      if (val != null && val.length > 0) {
        this.churchService.query(val).subscribe(
          contacts => {
            let a: any = ['Aktueller Standort'];
            a = a.concat(contacts.cities);
            a = a.concat(contacts.churches);
            this.filteredOptions.next(a);
          }, error => {
            this._snackBar.open('Es ist ein Fehler beim Laden aufgetreten', 'Ok', {
              duration: 3000,
            });
          });
      } else {
        this.filteredOptions.next([]);
      }
    });
  }

  displayFn(user: any): string {
    if (!user) {
      return '';
    }
    if (user === 'Aktueller Standort') {
      return user;
    }

    if (user.name) {
      return user.patrozinium;
    }

    return (isNaN(this.selected) ? '' : user.plz + ' ') + user.ort + (user.ortsteil ? '-' + user.ortsteil : '');
  }

  // handle account
  selectEntry(churchOrCity: any) {
    if (isDevMode()) console.log('selected ' + JSON.stringify(churchOrCity));
    if (churchOrCity === 'Aktueller Standort') {
      this.router.navigate(['/searchresult/geo']);
    } else if (churchOrCity.id && churchOrCity.level == 3) {
      this.router.navigate(['/church/' + churchOrCity.id]);
    } else if (churchOrCity.id) {
      this.router.navigate(['/searchresult'], {
        queryParams:
          { id: churchOrCity.id }
      });
    } else {
      this.router.navigate(['/searchresult'], {
        queryParams:
          { ort: churchOrCity.ort, ortsteil: churchOrCity.ortsteil }
      });
    }
  }

}
