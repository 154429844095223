import { Component, Input, OnInit } from '@angular/core';
import { ChurchInformation } from 'src/app/church';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  closedByDate: Map<string, boolean> = new Map();

  @Input() church: ChurchInformation = new ChurchInformation();

  constructor() {
  }

  ngOnInit(): void {
  }

  isExpanded(date: string) {
    return !(this.closedByDate.get(date));
  }

  toggle(date: string) {
    this.closedByDate.set(date, !this.closedByDate.get(date));
  }
}
