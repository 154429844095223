import { Component, ViewChild, OnInit, Input, isDevMode } from '@angular/core';
import { Church } from '../../church';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  @Input() churches: Church[] = [];

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  markerPositions: google.maps.LatLngLiteral[] = [];
  options: google.maps.MapOptions = {
    center: { lat: 50.774720, lng: 6.083920 },
    zoom: 10
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: '/assets/icons/avail.png'
  };

  selectedChurch: Church = new Church();

  constructor() { }

  ngOnChanges(changes: Church[]) {

    this.markerPositions.splice(0, this.markerPositions.length);
    let sumLat = 0;
    let sumLng = 0;
    let sum = 0;

    if (this.churches) {
      this.churches.forEach(c => {

        if (c.longitude !== 0 && c.latitude !== 0) {
          sum++;
          sumLat += c.latitude / 1000000.0;
          sumLng += c.longitude / 1000000.0;
          this.markerPositions.push({
            "lat": c.latitude / 1000000.0, "lng": c.longitude / 1000000.0
          });
        }
      });

      if (sum > 0) {
        this.options = {
          center: { lat: sumLat / sum, lng: sumLng / sum },
          zoom: 14
        };
      }
    }
  }

  ngOnInit(): void {
  }

  openInfoWindow(marker: MapMarker, church: Church) {
    this.selectedChurch = church;
    if (isDevMode()) console.log('church=' + JSON.stringify(church));
    this.infoWindow.open(marker);
  }
}
