<mat-drawer-container class="drawer-container" autosize>
    <mat-drawer #drawer mode="over">
        <ul class="drawer-menu">
            <li class="ka-select">
                <a class="drawer-menu-link" (click)="drawer.toggle()" (click)="goHome()">
                    <span class="material-icons">
                        home
                    </span>
                    <div class="drawer-menu-text">Start</div>
                </a>
            </li>
            <li class="ka-select">
                <a class="drawer-menu-link" (click)="drawer.toggle()" (click)="goDatenschutz()">
                    <span class="material-icons">
                        privacy_tip
                    </span>
                    <div class="drawer-menu-text">Datenschutz</div>
                </a>
            </li>
            <li class="ka-select">
                <a class="drawer-menu-link" (click)="drawer.toggle()" (click)="goImpressum()">
                    <span class="material-icons">
                        assignment
                    </span>
                    <div class="drawer-menu-text">Impressum</div>
                </a>
            </li>

            <div style="position: fixed; bottom: 10px; width: 250px;">
                <li class="ka-select">
                    <a class="drawer-menu-link" href="https://kirchenzeitung-aachen.de" target="_blank">
                        <img style="padding-left: 6px; " src="/assets/icons/kiz-logo.png" alt="Kirchenzeitung Aachen" />
                    </a>
                </li>
                <li>
                    <span style="padding-left: 16px; font-size: 0.6em;">{{environment.version}}</span>
                </li>
            </div>
        </ul>
    </mat-drawer>

    <div class="ka-tab-wrapper">
        <app-menu (clicked)="drawer.toggle()" [text]="headerTitle"></app-menu>
        <router-outlet></router-outlet>
    </div>

</mat-drawer-container>