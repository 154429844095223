import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HtmlService {

  constructor(private http: HttpClient) { }

  loadHtml(site: string): Observable<string> {
    return this.http.get(environment.appUrl + '/' + site + '.html', { responseType: 'text' })
  }

}
