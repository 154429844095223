<mat-toolbar *ngIf="!isIos" class="mat-toolbar-double-row">
    <button (click)="toggleDrawer()" mat-icon-button class="ka-icon" aria-label="Show menu">
        <mat-icon class="ka-select2">menu</mat-icon>
    </button>

    <div>
        <img aria-hidden src="/assets/icons/icon_church{{church.level}}b.png" height="35"
            style="float:left; padding-right: 5px; padding-top: 10px;" />
        <div style="display: inline-block; line-height: normal; margin-right: 40px; vertical-align: middle;">
            <span class="ka-church-type">{{church | churchtype}}</span><br />
            <span class="ka-church-name">{{church.patrozinium}}</span>
        </div>
    </div>

    <span class="example-spacer"></span>

    <button (click)="toggleStar(church)" mat-icon-button class="ka-icon favorite-icon" aria-label="star favorite">
        <mat-icon class="ka-select2">{{isStarred ? 'star' : 'star_border'}}</mat-icon>
    </button>
</mat-toolbar>

<mat-toolbar *ngIf="isIos" class="mat-toolbar-double-row">
    <button *ngIf="search" (click)="goBack()" mat-icon-button class="ka-icon" aria-label="Show menu">
        <mat-icon class="ka-selectIos">arrow_back_ios</mat-icon>
        <span class="ka-nav-text">Ergebnis</span>
    </button>
    <button *ngIf="!search" (click)="goHome()" mat-icon-button class="ka-icon" aria-label="Show menu">
        <mat-icon class="ka-selectIos">arrow_back_ios</mat-icon>
        <span class="ka-nav-text">Start</span>
    </button>
    <span class="example-spacer"></span>

    <div>
        <img aria-hidden src="/assets/icons/icon_church{{church.level}}b.png" height="35"
            style="float:left; padding-right: 5px; padding-top: 10px;" />
        <div style="display: inline-block; line-height: normal; margin-right: 40px; vertical-align: middle;">
            <span class="ka-church-type">{{church | churchtype}}</span><br />
            <span class="ka-church-name">{{church.patrozinium}}</span>
        </div>
    </div>

    <span class="example-spacer"></span>

    <button (click)="toggleStar(church)" mat-icon-button class="ka-icon favorite-icon" aria-label="star favorite">
        <mat-icon class="ka-select2">{{isStarred ? 'star' : 'star_border'}}</mat-icon>
    </button>
</mat-toolbar>