import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ChurchService } from '../services/church.service';
import { LocationService } from '../services/location.service';
import { Church } from '../church';

@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css']
})
export class SearchresultComponent implements OnInit {

  churches: Church[];
  errorText: string;

  ort: string = '';
  ortsteil: string = '';
  id: number = 0;

  isGeo: boolean = false;
  pos: any;

  constructor(
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private churchService: ChurchService,
    private locationService: LocationService) {

    if (isDevMode()) console.log('Called Constructor');
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.ort = params.ort;
        this.ortsteil = params.ortsteil;
        this.id = +params.id;
      }
    });

    if (this.route.snapshot.url.length > 1) {
      this.isGeo = (this.route.snapshot.url[1].path === 'geo');
    }
  }

  ngOnInit(): void {

    if (this.isGeo) {
      if (isDevMode()) console.log('loading geo');
      this.locationService.getPosition().then(pos => {
        if (isDevMode()) console.log(`Positon: ${pos.lng} ${pos.lat}`);
        this.pos = pos;

        this.churchService.getChurchesByGeo(
          Math.round(pos.lng * 1000000),
          Math.round(pos.lat * 1000000)).subscribe(
            churches => {
              this.churches = churches;
            }, error => {
              this._snackBar.open('Es ist ein Fehler beim Laden aufgetreten', 'Ok', {
                duration: 3000,
              });
            }
          );

      }, error => {
        if (error instanceof GeolocationPositionError) {
          if (isDevMode()) console.log('error=' + error.code);
          if (error.code === error.PERMISSION_DENIED) {
            this.errorText = 'Sie haben den Zugriff auf die Geoposition untersagt.';
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            this.errorText = 'Die aktuelle Geoposition konnte nicht bestimmt werden.';
          } else if (error.code === error.TIMEOUT) {
            this.errorText = 'Die aktuelle Geoposition konnte nicht bestimmt werden.';
          } else {
            this.errorText = 'Die aktuelle Geoposition konnte nicht bestimmt werden.';
          }

        } else {
          this.errorText = 'Die aktuelle Geoposition konnte nicht bestimmt werden.';
        }
        if (isDevMode()) console.log('failed' + JSON.stringify(error));

        this._snackBar.open(this.errorText, 'Ok', {
          duration: 3000,
        });

      });
    } else {
      if (this.id > 0) {
        if (isDevMode()) console.log('loading cities');
        this.churchService.getChurchesById(this.id).subscribe(
          churches => {
            this.churches = churches;
          }, error => {
            this._snackBar.open('Es ist ein Fehler beim Laden aufgetreten', 'Ok', {
              duration: 3000,
            });
          }
        );
      } else {
        if (isDevMode()) console.log('loading cities');
        this.churchService.getChurchesByCity(this.ort + (this.ortsteil ? '-' + this.ortsteil : '')).subscribe(
          churches => {
            this.churches = churches;
          }, error => {
            this._snackBar.open('Es ist ein Fehler beim Laden aufgetreten', 'Ok', {
              duration: 3000,
            });
          }
        );
      }
    }
  }
}
