<div *ngIf="church.massGroup">
    <div *ngFor="let group of church.massGroup">
        <button class="ka-church-toggle ka-select" mat-icon-button (click)="toggle(group.date)">
            <mat-icon class="mat-icon-rtl-mirror">
                {{isExpanded(group.date) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            <span>{{group.date}}</span>
        </button>

        <div *ngIf="isExpanded(group.date)">

            <div [class.ka-separator]="i > 0" class="ka-church-item ka-nolink"
                *ngFor="let m of group.masses; let i = index">
                {{m.time}} {{m.typ}}
                <a href="{{m.icsUrl}}" target=" _blank" class="ka-nolink">
                    <div style="padding-top: 10px;">
                        <img [src]="(m.imageUrl ? m.imageUrl : '/assets/icons/px.png')"
                            style="float:left; padding-right: 10px;" />
                        <div class="ka-mass-block">
                            <div style="padding-bottom: 10px;" *ngIf="m.description">{{m.description}}<br /></div>
                            <div style="color: gray;">
                                <mat-icon style="padding-bottom: 5px; float: left; padding-right: 5px;">place
                                </mat-icon>
                                {{m.ort}}
                            </div>
                        </div>

                    </div>
                </a>
            </div>
        </div>

    </div>

    <div class="ka-church-loading" *ngIf="
        !church.details.id || (church.details.id && church.details.id === '')">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
</div>

<div class="ka-church-nodata" *ngIf="church.massesError
&& church.massesError.length > 0">
    {{church.massesError}}
</div>