import { Pipe, PipeTransform } from '@angular/core';

import { Church } from './church'

@Pipe({
  name: 'churchtype'
})
export class ChurchtypePipe implements PipeTransform {

  transform(church: Church, ...args: unknown[]): unknown {
    if (!church || !church.level) {
      return '';
    }
    return (church.level === 2
      ? 'Pfarrei'
      : (church.level === 3
        ? 'Kirche'
        : (church.level === 1 ? 'GdG' : '')));
  }
}
