import { Component, OnInit, isDevMode } from '@angular/core';

import { DebugService } from '../debug.service'
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  debug: string = '';
  isDebug: boolean = false;
  environment: any;
  userAgent: any;
  image: string = '';
  images = ["/assets/taize.jpg", "/assets/segen.jpg", "/assets/kerzen.jpg"];

  constructor(
    private debugService: DebugService,
    private platform: Platform) {
    this.environment = environment;
  }

  ngOnInit() {
    const nb = +Math.floor(Math.random() * 3);
    this.image = this.images[nb];
    this.isDebug = this.debugService.isDebug();
    this.userAgent = navigator.userAgent + ";" + navigator.vendor
      + ";" + navigator.maxTouchPoints
      + "; size=" + ((window.innerWidth > 0) ? window.innerWidth : screen.width);

    if (isDevMode()) console.log('start invoked, debug=' + this.isDebug);

    this.debug =
      'android=' + this.platform.ANDROID
      + "; ios=" + this.platform.IOS
      + "; blink=" + this.platform.BLINK
      + "; edge=" + this.platform.EDGE
      + "; ff=" + this.platform.FIREFOX
      + "; safari=" + this.platform.SAFARI
      + "; trident=" + this.platform.TRIDENT
      + "; webkit=" + this.platform.WEBKIT
      + "; browser=" + this.platform.isBrowser
      + ";" + JSON.stringify(window.navigator)
  }
}
