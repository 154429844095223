import { Injectable, isDevMode } from '@angular/core';
import { Church } from '../church';

const KEY: string = 'starred';

@Injectable({
  providedIn: 'root'
})
export class StarService {

  constructor() { }

  isStarred(id: string): boolean {
    let val = window.localStorage.getItem(KEY);
    //if (isDevMode()) console.log('isStarred ' + JSON.stringify(val));

    if (val) {
      const s = JSON.parse(val);

      //if (isDevMode()) console.log('isStarred ' + s[id]);
      return (s[id]);
    }
    return false;
  }

  toggleStar(church: Church): boolean {
    let val = window.localStorage.getItem(KEY);
    if (isDevMode()) console.log('isStarred ' + val);

    if (val) {
      const s = JSON.parse(val);

      //if (isDevMode()) console.log('isStarred ' + s[church.id]);
      if (s[church.id]) {
        s[church.id] = null;
        window.localStorage.setItem(KEY, JSON.stringify(s));
        return false;
      } else {
        s[church.id] = church;
        window.localStorage.setItem(KEY, JSON.stringify(s));

        return true;
      }
    }

    let s: any = {};
    s[church.id] = church;
    window.localStorage.setItem(KEY, JSON.stringify(s));

    return true;
  }

  getStarred(): Map<string, Church> {
    let val = window.localStorage.getItem(KEY);

    if (!val) {
      const s: any = {};
      s["80619"] = JSON.parse('{"id":"80619","name":"Aachener Dom | Domkapitel - Mitte","strasse":"Klosterplatz","no":"2","ort":"Aachen","ortsteil":"Mitte","plz":52062,"level":2}');
      window.localStorage.setItem(KEY, JSON.stringify(s));

      return s;
    }

    if (val) {
      const s = JSON.parse(val);
      //if (isDevMode()) console.log('s=' + JSON.stringify(s));

      return s;
    }

    return new Map();
  }
}
