<mat-tab-group animationDuration="0ms" style="height: calc(100% - 56px);">
    <mat-tab label="Liste">
        <app-list [churches]="churches"></app-list>
    </mat-tab>
    <mat-tab label="Karte">
        <app-map *ngIf="churches && !errorText" [churches]="churches"></app-map>
    </mat-tab>
</mat-tab-group>

<div class="ka-church-loading" *ngIf="!churches && !errorText">
    <mat-spinner [diameter]="30"></mat-spinner>
</div>