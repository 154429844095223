import { NgModule, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { WebComponent } from './web/web.component';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabGroupGestureModule } from '@angular-material-gesture/mat-tab-group-gesture';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';

import { ChurchComponent } from './church/church.component';
import { ChurchWidget } from './widgets/church/church.widget';
import { EventsComponent } from './church/events/events.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { InfoComponent } from './church/info/info.component';
import { ListComponent } from './searchresult/list/list.component';
import { MapComponent } from './searchresult/map/map.component';
import { MassComponent } from './church/mass/mass.component';
import { MenuComponent } from './menu/menu.component';
import { Menu2Component } from './menu2/menu2.component';
import { MyErrorHandler } from './error-handler';
import { NewsComponent } from './church/news/news.component';
import { SearchComponent } from './search/search.component';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { ShellComponent } from './shell/shell.component';
import { StartComponent } from './start/start.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { ChurchtypePipe } from './churchtype.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SearchresultComponent,
    FavoritesComponent,
    ChurchComponent,
    MassComponent,
    InfoComponent,
    EventsComponent,
    NewsComponent,
    ListComponent,
    MapComponent,
    ShellComponent,
    StartComponent,
    MenuComponent,
    ChurchWidget,
    WebComponent,
    Menu2Component,
    ChurchtypePipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    MatCardModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTabGroupGestureModule,
    MatToolbarModule,
    MatTreeModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: MyErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
