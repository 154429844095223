<br />

<app-search></app-search>

<div class="wrapper">
    <img [src]="image" />
</div>

<div style="padding:10px;">
    <app-favorites></app-favorites>

    <div class="ka-hint">
        <br />
        <div style="padding-top:20px; padding-left: 10px; padding-right: 10px;">
            <mat-card>
                <mat-card-title>Gottesdienste im Bistum Aachen</mat-card-title>
                <mat-card-content>
                    Herzlich willkommen beim Gottesdienstfinder – einem Projekt des Einhard-Verlags und der Aachener
                    KirchenZeitung in Kooperation mit dem Bistum Aachen!
                    <br />
                    <br />
                    Die App lässt nicht nur eine Gottesdienst-Suche über Region oder Pfarrei zu, sie bietet auch eine
                    Umkreissuche und zeigt Ihnen als Liste oder Karte die in der Nähe gelegenen Orte.
                    <br />
                    <br />
                    Darüber hinaus sind Informationen zu den Kirchen und Gemeinden aufgelistet, und in der Rubrik
                    „Termine“ gibt es Hinweise auf Veranstaltungen mit kirchlichem Bezug.
                    <br />
                    <br />
                    Schauen Sie einfach selbst!
                </mat-card-content>
            </mat-card>
        </div>

        <br />
        <div style="padding-top:20px; padding-left: 10px; padding-right: 10px;">
            <mat-card>
                <mat-card-title>Hinweise zur Nutzung der App</mat-card-title>
                <mat-card-content>
                    Ein <b>einfaches</b> Kirchensymbol verweist auf einen einzelnen Gottesdienstort. Das
                    kann sein eine Kirche oder Kapelle, ein Kloster, ein Krankenhaus, ein Wohnheim…
                    <br />
                    <br />
                    Ein <b>doppeltes</b> Kirchensymbol weist auf eine Pfarrei hin.
                    <br />
                    <br />
                    Ein <b>dreifaches</b> Kirchensymbol zeigt an, dass Sie sich auf der Ebene einer GdG oder
                    einer fusionierten Pfarrei befinden.
                    <br />
                    <br />
                    Die Rubrik <b>„Termine“</b> wird ausschließlich auf der Ebene der fusionierten Pfarreien
                    oder Gemeinschaften der Gemeinden (GdG) gepflegt. Hier finden Sie also auch
                    ausgesuchte Angebote einzelner Gemeinden.
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<div class="ka-debug" *ngIf="isDebug" style="padding: 10px; ">
    Debug Info
    <br />
    <p>
        url = {{environment.serviceUrl}}
    </p>
    {{debug}}
    <p>
        Version {{environment.version}}
    </p>
    <p>
        Time {{environment.buildtime}}
    </p>
    <p>
        {{userAgent}}
    </p>
</div>