import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { Church } from 'src/app/church';
import { DebugService } from '../../debug.service';

@Component({
  selector: 'church-widget',
  templateUrl: './church.widget.html',
  styleUrls: ['./church.widget.css']
})
export class ChurchWidget implements OnInit {

  @Input() dark: boolean = false;
  @Input() search: boolean = false;
  @Input() church: Church = new Church();

  isDebug: boolean = false;

  constructor(private router: Router,
    private debugService: DebugService) { }

  ngOnInit(): void {
    this.isDebug = this.debugService.isDebug();
  }

  click() {
    if (isDevMode()) console.log('selected ' + this.church.id);
    this.router.navigate(['/church/' + this.church.id]);
  }
}
