import { Component, OnInit, Output, isDevMode, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChurchInformation } from '../church';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() text: string = '';
  @Output() clicked = new EventEmitter<any>();

  isIos: boolean = false;
  isStart: boolean = true;

  church: ChurchInformation = new ChurchInformation();

  constructor(
    private router: Router) {
    this.isIos = (
      /iPad|iPhone|iPod|MacIntel/.test(navigator.userAgent)
      || (/Mac/.test(navigator.userAgent) && navigator.maxTouchPoints > 2)
    );
  }

  ngOnInit(): void {
    this.isStart = (this.router.url.length <= 1);
  }

  toggleDrawer(): void {
    this.clicked.emit();
  }

  goHome() {
    this.router.navigate(['/'], {
      replaceUrl: true,
      skipLocationChange: true
    });
  }
}
