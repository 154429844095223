import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';

import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

class QError {
    date: string;
    site: string;
    path: string;
    message: string;
    name: string;
    userAgent: string;
    httpCode: number;
    httpError: string;
    httpStatusText: string;
    stack: string;
}

@Injectable()
export class MyErrorHandler extends ErrorHandler {

    environment: any;

    constructor(private http: HttpClient,
        private location: Location) {
        super();
        this.environment = environment;
    }

    handleError(error: any) {
        super.handleError(error);
        this.logError(error);
    }

    logError(error: any) {
        const e: QError = new QError;
        e.path = this.location.path(false);
        e.date = new Date().toISOString();
        e.site = error.constructor.name;
        e.message = error.message;
        e.userAgent = navigator.userAgent;

        if (error instanceof HttpErrorResponse) {
            e.httpCode = (<HttpErrorResponse>error).status;
            e.httpError = (<HttpErrorResponse>error).error;
            e.httpStatusText = (<HttpErrorResponse>error).statusText;
        }

        e.stack = error.stack;
        e.name = error.name;

        if (isDevMode()) {
            if (isDevMode()) console.log('sending ' + JSON.stringify(e));
        } else {
            //this.sendIssue(e).subscribe(
            //    result => {
            //        if (isDevMode()) { console.log('error send'); }
            //    }
            //);
        }
    }

    sendIssue(error: any): Observable<any> {
        return this.http.post<any>('https://mobilesol.de/report/ka-pwa', error);
    }
}
