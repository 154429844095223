<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Pfarrei, Ort, PLZ</mat-label>
        <input type="text" aria-label="Pfarrei, Ort, PLZ" matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete id="search" name="search" autoActiveFirstOption
            (optionSelected)="selectEntry($event.option.value)" #auto="matAutocomplete" [displayWith]="displayFn"
            [autoActiveFirstOption]="false">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" class="ka-select"
                [class.mat-option-church]="option.name">
                <img *ngIf="option === 'Aktueller Standort'" class="example-option-img" style="padding-top: 10px;"
                    aria-hidden src="/assets/icons/a10_device_access_location_found.png" height="25">
                <img *ngIf="option.name && option.level !== 0" class="example-option-img" aria-hidden
                    src="/assets/icons/icon_church{{option.level}}.png" height="25">
                <div style="width: calc(100% - 50px);">
                    <span>{{displayFn(option)}}</span>
                    <small *ngIf="option.name"><br />{{option.ort}}{{option.ortsteil ? '-' + option.ortsteil :
                        ''}}</small>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>