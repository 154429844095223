<div style="height: 100%;">
    <google-map [options]=" options">
        <map-marker #marker="mapMarker" *ngFor="let c of churches"
            [position]="{'lat': c.latitude/1000000.0, 'lng': c.longitude/1000000.0 }"
            (mapClick)="openInfoWindow(marker, c)" [options]="markerOptions">
        </map-marker>

        <map-info-window>
            <a class="ka-nolink" href="/church/{{selectedChurch.id}}?search=1">
                <b>{{selectedChurch.name}}</b>
                <br />
                {{selectedChurch.strasse}} {{selectedChurch.no}}
                <br />
                {{selectedChurch.plz}} {{selectedChurch.ort}}-{{selectedChurch.ortsteil}}
            </a>
        </map-info-window>
    </google-map>
</div>