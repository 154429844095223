import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit {

  headerTitle: string = '';
  environment: any;

  constructor(
    private router: Router) {
    this.environment = environment;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const txt = this.router.url.split('/');
    if (txt.length > 2 && txt[2] === 'datenschutz') {
      this.headerTitle = 'Datenschutz';
    } else if (txt.length > 2 && txt[2] === 'impressum') {
      this.headerTitle = 'Impressum';
    } else if (txt.length > 1 && txt[1].startsWith('searchresult')) {
      this.headerTitle = 'Ergebnis';
    } else {
      this.headerTitle = 'Start';
    }
  }

  goHome() {
    this.router.navigate(['/'], {
      replaceUrl: true,
      skipLocationChange: true
    });
  }

  goDatenschutz() {
    this.router.navigate(['/web/datenschutz']);
  }

  goImpressum() {
    this.router.navigate(['/web/impressum']);
  }

  go(url: string) {
    window.location.href = url;
  }
}
