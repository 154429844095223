import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  count = 0;
  constructor() { }

  isDebug() {
    return this.count > 15;
  }

  incCount() {
    this.count++;
    if (this.isDebug()) {
      if (isDevMode()) console.log('you got it');
    }
  }

  setCount(cnt: number) {
    this.count = cnt;
  }
}
